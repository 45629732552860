<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('Flight search result') }}</h2>
                        <ul>
                            <li><a :href="home_url">{{ $t('Home') }}</a></li>
                            <li><span><i class="fas fa-circle"></i></span> {{ $t('Flight search result') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "Banner",
    data() {
        return {
            home_url: '',
        }
    },
    mounted() {
        this.home_url = localStorage.getItem("home_url");
    }
};
</script>