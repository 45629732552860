<template>

    <!-- Common Banner Area -->
    <BookingHelpBanner />

    <!-- Dashboard Area -->
    <BookingHelp />

</template>
<script>

import BookingHelpBanner from '@/components/booking-help/BookingHelpBanner.vue'
import BookingHelp from '@/components/booking-help/BookingHelp.vue'


export default {
    name: "BookingHelpView",
    components: {
        BookingHelpBanner, BookingHelp
    }
};
</script>
