<template>
    <section id="theme_search_form_tour" class="fligth_top_search_main_form_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="flight_categories_search">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li v-if="allowed_request_return_flight_only == 1"
                                            :class="'nav-item ' + (this.tripType == 'oneway' ? 'active' : '')"
                                            role="presentation">
                                            <button :class="'nav-link ' + (this.tripType == 'oneway' ? 'active' : '')"
                                                id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway" type="button"
                                                role="tab" aria-controls="oneway" aria-selected="false">
                                                {{ $t('Return only') }}</button>
                                        </li>
                                        <li :class="'nav-item ' + (this.tripType == 'roundtrip' ? 'active' : '')"
                                            role="presentation">
                                            <button :class="'nav-link ' + (this.tripType == 'roundtrip' ? 'active' : '')"
                                                id="roundtrip-tab" data-bs-toggle="tab" data-bs-target="#roundtrip"
                                                type="button" role="tab" aria-controls="roundtrip" aria-selected="false">
                                                {{ $t('Roundtrip') }}</button>
                                        </li>
                                        <li v-if="multi_destinations == 1"
                                            :class="'nav-item ' + (this.tripType == 'multi_city' ? 'active' : '')"
                                            role="presentation">
                                            <button :class="'nav-link ' + (this.tripType == 'multi_city' ? 'active' : '')"
                                                id="multi_city-tab" data-bs-toggle="tab" data-bs-target="#multi_city"
                                                type="button" role="tab" aria-controls="multi_city" aria-selected="false">
                                                {{ $t('Multi-City') }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tab-content" id="myTabContent1">
                                    <div v-if="allowed_request_return_flight_only == 1"
                                        :class="'tab-pane fade ' + (tripType == 'oneway' ? 'show active' : '')" id="oneway"
                                        role="tabpanel" aria-labelledby="oneway-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="multi_city_form_wrapper">
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('From') }}</p>
                                                                            <input type="text" value="RUH"
                                                                                :placeholder="$t('City or Airport')"
                                                                                readonly>
                                                                            <span class="departure_name"></span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('To') }}</p>
                                                                            <input type="text" v-model="arrival"
                                                                                @input="handle_airports_search_oneway"
                                                                                :placeholder="$t('City or Airport')">
                                                                            <span class="arrival_name">
                                                                                {{ arrival_name }}
                                                                            </span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                        <ul v-if="is_searching" tabindex="0"
                                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                            style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                                aria-disabled="true">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    id="ui-id-8" tabindex="-1">
                                                                                    {{ $t('Nearest airport') }}
                                                                                </a>
                                                                            </li>
                                                                            <li v-for="airport in airports_data"
                                                                                class="fl-route-list ui-menu-item"
                                                                                @click="selectAirportOneway(airport)">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    tabindex="-1">
                                                                                    <div class="inline-block airportMargin">
                                                                                        <span class="fl-loc">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en' ? airport.en_name :
                                                                                                airport.ar_name
                                                                                            }}
                                                                                        </span>
                                                                                        <span class="fl-country">
                                                                                            {{
                                                                                                this.$i18n.locale ==
                                                                                                'en' ? airport.en_city :
                                                                                                airport.ar_city
                                                                                            }}
                                                                                            ,
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_country :
                                                                                                airport.ar_country
                                                                                            }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="pull-right">
                                                                                        <h4 class="fl-route-code">{{
                                                                                            airport.code }}
                                                                                        </h4>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li v-if="noResults"
                                                                                class="fl-route-list ui-menu-item no-results">
                                                                                {{ $t('No results!') }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div class="flight_Search_boxed">
                                                                                <div class="Journey_date">
                                                                                    <p>{{ $t('Departure Date') }}</p>
                                                                                    <VueDatePicker v-model="date"
                                                                                        :enable-time-picker="false"
                                                                                        :min-date="arrival_min_date"
                                                                                        :max-date="arrival_max_date"
                                                                                        @internal-model-change="handleInternal"
                                                                                        :placeholder="$t('Departure Date')"
                                                                                        :start-date="arrival_min_date"
                                                                                        prevent-min-max-navigation />
                                                                                    <span class="departure_day">
                                                                                        {{ this.date ? $t(new
                                                                                            Date(this.date).toLocaleDateString('en-US',
                                                                                                { weekday: 'long' })) : '' }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-1  col-md-6 col-sm-12 col-12 p-r-0">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>{{ $t('Passengers') }}</p>
                                                                            <div class="dropdown">
                                                                                <button class="dropdown-toggle final-count"
                                                                                    type="button">
                                                                                    {{ number }} {{ $t('passenger') }}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="this.$route.query.guest_id && companions.length > 0"
                                                                            class="form-group check-companion">
                                                                            <input type="checkbox" id="booking-companion"
                                                                                class="form-check-input filter-stops"
                                                                                v-model="booking_for_companion"
                                                                                @change="handle_booking_for_companion">
                                                                            <label for="booking-companion">
                                                                                {{ $t('booking for companion as well') }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-1  col-md-6 col-sm-12 col-12 p-r-0 ">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>{{ $t('Class') }} </p>
                                                                            <div class="dropdown">
                                                                                <button v-if="this.allowedDegree.length > 1"
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    {{ $t(cabin) }}
                                                                                </button>
                                                                                <button v-else
                                                                                    class="dropdown-toggle final-count"
                                                                                    type="button">
                                                                                    {{ $t(cabin) }}
                                                                                </button>
                                                                                <div v-if="this.allowedDegree.length > 1"
                                                                                    class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div class="traveller-calulate-persons">
                                                                                        <div class="cabin-selection">
                                                                                            <h6>{{ $t('Cabin Class') }}</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button
                                                                                                    v-if="this.allowedDegree.includes('economy')"
                                                                                                    type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-eco"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="ECONOMY"
                                                                                                        class="chk-cabin">
                                                                                                    <label for="cb-eco">
                                                                                                        <span
                                                                                                            class="muiButton-label">
                                                                                                            {{ $t('ECONOMY')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                                <button
                                                                                                    v-if="this.allowedDegree.includes('business')"
                                                                                                    type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-bus"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="BUSINESS"
                                                                                                        class="chk-cabin">
                                                                                                    <label for="cb-bus">
                                                                                                        <span
                                                                                                            class="muiButton-label">
                                                                                                            {{
                                                                                                                $t('BUSINESS')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                                <button
                                                                                                    v-if="this.allowedDegree.includes('first class')"
                                                                                                    type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-firs"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="FIRST"
                                                                                                        class="chk-cabin">
                                                                                                    <label
                                                                                                        for="cb-firs"><span
                                                                                                            class="muiButton-label">
                                                                                                            {{ $t('FIRST')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button type="button"
                                                                class="btn btn_theme btn_md btn-search-flight-next"
                                                                @click="search">{{
                                                                    $t('Search Flight')
                                                                }}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="'tab-pane fade ' + (tripType == 'roundtrip' ? 'show active' : '')"
                                        id="roundtrip" role="tabpanel" aria-labelledby="roundtrip-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="row">
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12 relative">
                                                                <div class="flight_Search_boxed">
                                                                    <p>{{ $t('From') }}</p>
                                                                    <input type="text" class="departure" name="departure"
                                                                        v-model="departure" @input="handle_airports_search"
                                                                        :placeholder="$t('City or Airport')">
                                                                    <span class="departure_name">{{ departure_name }}</span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                                <ul v-if="is_searching" tabindex="0"
                                                                    class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                    style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                    <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                        aria-disabled="true">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            id="ui-id-8" tabindex="-1">
                                                                            {{ $t('Nearest airport') }}
                                                                        </a>
                                                                    </li>
                                                                    <li v-for="airport in airports_data"
                                                                        class="fl-route-list ui-menu-item"
                                                                        @click="selectAirport(airport)">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            tabindex="-1">
                                                                            <div class="inline-block airportMargin">
                                                                                <span class="fl-loc">
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en'
                                                                                        ? airport.en_name : airport.ar_name
                                                                                    }}
                                                                                </span>
                                                                                <span class="fl-country">
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en'
                                                                                        ? airport.en_city : airport.ar_city
                                                                                    }} ,
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en'
                                                                                        ? airport.en_country :
                                                                                        airport.ar_country
                                                                                    }}
                                                                                </span>
                                                                            </div>
                                                                            <div class="pull-right">
                                                                                <h4 class="fl-route-code">{{ airport.code }}
                                                                                </h4>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li v-if="noResults"
                                                                        class="fl-route-list ui-menu-item no-results">
                                                                        {{ $t('No results!') }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12 relative">
                                                                <div class="flight_Search_boxed">
                                                                    <p>{{ $t('To') }}</p>
                                                                    <input type="text" class="arrival" name="arrival"
                                                                        :placeholder="$t('City or Airport')" readonly>
                                                                    <span class="arrival_name"></span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div class="range_plan">
                                                                        <i class="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed date_flex_area">
                                                                        <div class="Journey_date">
                                                                            <p>{{ $t('Departure Date') }}</p>
                                                                            <VueDatePicker v-model="date"
                                                                                :enable-time-picker="false"
                                                                                :min-date="departure_min_date"
                                                                                :max-date="departure_max_date"
                                                                                :start-date="departure_min_date"
                                                                                prevent-min-max-navigation
                                                                                @internal-model-change="handleInternal"
                                                                                :placeholder="$t('Departure Date')" />
                                                                            <span class="departure_day">
                                                                                {{ this.date ? $t(new
                                                                                    Date(this.date).toLocaleDateString('en-US',
                                                                                        { weekday: 'long' })) : '' }}
                                                                            </span>
                                                                        </div>
                                                                        <div class="Journey_date">
                                                                            <p>{{ $t('Return Date') }}</p>
                                                                            <VueDatePicker v-model="arrival_date"
                                                                                :enable-time-picker="false"
                                                                                :min-date="arrival_min_date"
                                                                                :max-date="arrival_max_date"
                                                                                :start-date="arrival_min_date"
                                                                                prevent-min-max-navigation
                                                                                @internal-model-change="arrivalHandleInternal"
                                                                                :placeholder="$t('Return Date')" />
                                                                            <span class="arrival_day">
                                                                                {{ this.arrival_date ? $t(new
                                                                                    Date(this.arrival_date).toLocaleDateString('en-US',
                                                                                        { weekday: 'long' })) : '' }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-1 col-md-6 col-sm-12 col-12 p-r-0">
                                                                <div class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>{{ $t('passenger') }}</p>
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            type="button">
                                                                            {{ number }} {{ $t('passenger') }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div v-if="this.$route.query.guest_id && companions.length > 0"
                                                                    class="form-group check-companion">
                                                                    <input type="checkbox" id="booking-companion"
                                                                        class="form-check-input filter-stops"
                                                                        v-model="booking_for_companion"
                                                                        @change="handle_booking_for_companion">
                                                                    <label for="booking-companion">
                                                                        {{ $t('booking for companion as well') }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-1  col-md-6 col-sm-12 col-12 p-0">
                                                                <div class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>{{ $t('Class') }} </p>
                                                                    <div class="dropdown">
                                                                        <button v-if="this.allowedDegree.length > 1"
                                                                            class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                                            {{ $t(cabin) }}
                                                                        </button>
                                                                        <button v-else class="dropdown-toggle final-count"
                                                                            type="button">
                                                                            {{ $t(cabin) }}
                                                                        </button>

                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="cabin-selection">
                                                                                    <h6>{{ $t('Cabin Class') }}</h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-eco" type="radio"
                                                                                                v-model="cabin" name="cabin"
                                                                                                value="ECONOMY"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-eco">
                                                                                                <span
                                                                                                    class="muiButton-label">
                                                                                                    {{ $t('ECONOMY')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-bus" type="radio"
                                                                                                v-model="cabin" name="cabin"
                                                                                                value="BUSINESS"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-bus">
                                                                                                <span
                                                                                                    class="muiButton-label">
                                                                                                    {{
                                                                                                        $t('BUSINESS')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-firs" type="radio"
                                                                                                v-model="cabin" name="cabin"
                                                                                                value="FIRST"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-firs"><span
                                                                                                    class="muiButton-label">
                                                                                                    {{ $t('FIRST')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button type="button"
                                                                class="btn btn_theme btn_md btn-search-flight"
                                                                @click="search">{{
                                                                    $t('Search Flight')
                                                                }}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="multi_destinations == 1"
                                        :class="'tab-pane fade ' + (tripType == 'multi_city' ? 'show active' : '')"
                                        id="multi_city" role="tabpanel" aria-labelledby="multi_city-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="multi_city_form_wrapper">
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('From') }}</p>
                                                                            <input type="text" class="departure"
                                                                                v-model="multi_city[0].from"
                                                                                name="departure"
                                                                                :placeholder="$t('City or Airport')"
                                                                                @input="handle_airports_search_multi_city(multi_city[0].from, 'departure', 0)">
                                                                            <span class="departure_name">{{
                                                                                airport_name_multi[0]['departure'] }}</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                        <ul v-if="is_searching_multi[0]['departure']"
                                                                            tabindex="0"
                                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                            style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                                aria-disabled="true">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    id="ui-id-8" tabindex="-1">
                                                                                    {{ $t('Nearest airport') }}
                                                                                </a>
                                                                            </li>
                                                                            <li v-for="airport in airports_data"
                                                                                class="fl-route-list ui-menu-item"
                                                                                @click="selectAirportMultiCity(airport, 0, 'departure')">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    tabindex="-1">
                                                                                    <div class="inline-block airportMargin">
                                                                                        <span class="fl-loc">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_name :
                                                                                                airport.ar_name
                                                                                            }}
                                                                                        </span>
                                                                                        <span class="fl-country">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_city :
                                                                                                airport.ar_city
                                                                                            }} ,
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_country :
                                                                                                airport.ar_country
                                                                                            }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="pull-right">
                                                                                        <h4 class="fl-route-code">{{
                                                                                            airport.code }}
                                                                                        </h4>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li v-if="noResults"
                                                                                class="fl-route-list ui-menu-item no-results">
                                                                                {{ $t('No results!') }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div
                                                                        class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('To') }}</p>
                                                                            <input type="text" class="arrival"
                                                                                name="arrival" v-model="multi_city[0].to"
                                                                                @input="handle_airports_search_multi_city(multi_city[0].to, 'arrival', 0)"
                                                                                :placeholder="$t('City or Airport')"
                                                                                readonly>
                                                                            <span class="arrival_name">{{
                                                                                airport_name_multi[0]['arrival'] }}</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                        <ul v-if="is_searching_multi[0]['arrival']"
                                                                            tabindex="0"
                                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                            style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                                aria-disabled="true">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    id="ui-id-8" tabindex="-1">
                                                                                    {{ $t('Nearest airport') }}
                                                                                </a>
                                                                            </li>
                                                                            <li v-for="airport in airports_data"
                                                                                class="fl-route-list ui-menu-item"
                                                                                @click="selectAirportMultiCity(airport, 0, 'arrival')">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    tabindex="-1">
                                                                                    <div class="inline-block airportMargin">
                                                                                        <span class="fl-loc">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_name :
                                                                                                airport.ar_name
                                                                                            }}
                                                                                        </span>
                                                                                        <span class="fl-country">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_city :
                                                                                                airport.ar_city
                                                                                            }} ,
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_country :
                                                                                                airport.ar_country
                                                                                            }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="pull-right">
                                                                                        <h4 class="fl-route-code">{{
                                                                                            airport.code }}
                                                                                        </h4>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li v-if="noResults"
                                                                                class="fl-route-list ui-menu-item no-results">
                                                                                {{ $t('No results!') }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div class="flight_Search_boxed">
                                                                                <div class="Journey_date">
                                                                                    <p>{{ $t('Departure Date') }}</p>
                                                                                    <VueDatePicker
                                                                                        v-model="multi_city[0].date"
                                                                                        :enable-time-picker="false"
                                                                                        :min-date="departure_min_date"
                                                                                        :max-date="departure_max_date"
                                                                                        :start-date="departure_min_date"
                                                                                        prevent-min-max-navigation
                                                                                        @internal-model-change="handleInternalMultiCity"
                                                                                        :placeholder="$t('Departure Date')" />
                                                                                    <span class="departure_day">
                                                                                        {{ this.multi_city[0].date ? $t(new
                                                                                            Date(multi_city[0].date).toLocaleDateString('en-US',
                                                                                                { weekday: 'long' })) : '' }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-1 col-md-6 col-sm-12 col-12 p-r-0">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>{{ $t('passenger') }}</p>
                                                                            <div class="dropdown">
                                                                                <button class="dropdown-toggle final-count"
                                                                                    type="button">
                                                                                    {{ number }} {{ $t('passenger') }}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="this.$route.query.guest_id && companions.length > 0"
                                                                            class="form-group check-companion">
                                                                            <input type="checkbox" id="booking-companion"
                                                                                class="form-check-input filter-stops"
                                                                                v-model="booking_for_companion"
                                                                                @change="handle_booking_for_companion">
                                                                            <label for="booking-companion">
                                                                                {{ $t('booking for companion as well') }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-1  col-md-6 col-sm-12 col-12 p-0">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>{{ $t('Class') }} </p>
                                                                            <div class="dropdown">
                                                                                <button v-if="this.allowedDegree.length > 1"
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    {{ $t(cabin) }}
                                                                                </button>
                                                                                <button v-else
                                                                                    class="dropdown-toggle final-count"
                                                                                    type="button">
                                                                                    {{ $t(cabin) }}
                                                                                </button>

                                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div class="traveller-calulate-persons">
                                                                                        <div class="cabin-selection">
                                                                                            <h6>{{ $t('Cabin Class') }}</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-eco"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="ECONOMY"
                                                                                                        class="chk-cabin">
                                                                                                    <label for="cb-eco">
                                                                                                        <span
                                                                                                            class="muiButton-label">
                                                                                                            {{ $t('ECONOMY')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-bus"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="BUSINESS"
                                                                                                        class="chk-cabin">
                                                                                                    <label for="cb-bus">
                                                                                                        <span
                                                                                                            class="muiButton-label">
                                                                                                            {{
                                                                                                                $t('BUSINESS')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <input id="cb-firs"
                                                                                                        type="radio"
                                                                                                        v-model="cabin"
                                                                                                        name="cabin"
                                                                                                        value="FIRST"
                                                                                                        class="chk-cabin">
                                                                                                    <label
                                                                                                        for="cb-firs"><span
                                                                                                            class="muiButton-label">
                                                                                                            {{ $t('FIRST')
                                                                                                            }}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-for='(rowDat, index) in rowData'>
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div class="col-lg-12" v-if="isHidden && index > 0">
                                                                        <div class="multi_form_remove">
                                                                            <button type="button"
                                                                                @click="deleteRow(index)">{{ $t('Remove')
                                                                                }}</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('From') }}</p>
                                                                            <input type="text" class="departure"
                                                                                name="departure"
                                                                                v-model="multi_city[index + 1].from"
                                                                                @input="handle_airports_search_multi_city(multi_city[index + 1].from, 'departure', index + 1)"
                                                                                :placeholder="$t('City or Airport')"
                                                                                :readonly="index == 0 ? true : false">
                                                                            <span class="departure_name">{{
                                                                                airport_name_multi[index + 1]['departure']
                                                                            }}</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                        <ul v-if="is_searching_multi[index + 1]['departure']"
                                                                            tabindex="0"
                                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                            style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                                aria-disabled="true">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    id="ui-id-8" tabindex="-1">
                                                                                    {{ $t('Nearest airport') }}
                                                                                </a>
                                                                            </li>
                                                                            <li v-for="airport in airports_data"
                                                                                class="fl-route-list ui-menu-item"
                                                                                @click="selectAirportMultiCity(airport, index + 1, 'departure')">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    tabindex="-1">
                                                                                    <div class="inline-block airportMargin">
                                                                                        <span class="fl-loc">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_name :
                                                                                                airport.ar_name
                                                                                            }}
                                                                                        </span>
                                                                                        <span class="fl-country">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_city :
                                                                                                airport.ar_city
                                                                                            }} ,
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_country :
                                                                                                airport.ar_country
                                                                                            }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="pull-right">
                                                                                        <h4 class="fl-route-code">{{
                                                                                            airport.code }}
                                                                                        </h4>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li v-if="noResults"
                                                                                class="fl-route-list ui-menu-item no-results">
                                                                                {{ $t('No results!') }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="col-lg-3 relative">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>{{ $t('To') }}</p>
                                                                            <input type="text" class="arrival"
                                                                                name="arrival"
                                                                                v-model="multi_city[index + 1].to"
                                                                                @input="handle_airports_search_multi_city(multi_city[index + 1].to, 'arrival', index + 1)"
                                                                                :placeholder="$t('City or Airport')">
                                                                            <span class="arrival_name">{{
                                                                                airport_name_multi[index + 1]['arrival']
                                                                            }}</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                        <ul v-if="is_searching_multi[index + 1]['arrival']"
                                                                            tabindex="0"
                                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
                                                                            style="top: 110px;z-index: 1060;left: 0px;text-align: initial;right: 0;">
                                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                                aria-disabled="true">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    id="ui-id-8" tabindex="-1">
                                                                                    {{ $t('Nearest airport') }}
                                                                                </a>
                                                                            </li>
                                                                            <li v-for="airport in airports_data"
                                                                                class="fl-route-list ui-menu-item"
                                                                                @click="selectAirportMultiCity(airport, index + 1, 'arrival')">
                                                                                <a class="fl-routes ui-menu-item-wrapper"
                                                                                    tabindex="-1">
                                                                                    <div class="inline-block airportMargin">
                                                                                        <span class="fl-loc">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_name :
                                                                                                airport.ar_name
                                                                                            }}
                                                                                        </span>
                                                                                        <span class="fl-country">
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_city :
                                                                                                airport.ar_city
                                                                                            }} ,
                                                                                            {{ this.$i18n.locale ==
                                                                                                'en'
                                                                                                ? airport.en_country :
                                                                                                airport.ar_country
                                                                                            }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="pull-right">
                                                                                        <h4 class="fl-route-code">{{
                                                                                            airport.code }}
                                                                                        </h4>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li v-if="noResults"
                                                                                class="fl-route-list ui-menu-item no-results">
                                                                                {{ $t('No results!') }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <div class="form_search_date">
                                                                            <div class="flight_Search_boxed">
                                                                                <div class="Journey_date">
                                                                                    <p>{{ $t('Departure Date') }}</p>
                                                                                    <VueDatePicker
                                                                                        v-model="multi_city[index + 1].date"
                                                                                        :enable-time-picker="false"
                                                                                        :min-date="arrival_min_date"
                                                                                        :max-date="arrival_max_date"
                                                                                        :start-date="arrival_min_date"
                                                                                        prevent-min-max-navigation
                                                                                        @internal-model-change="handleInternalMultiCity"
                                                                                        :placeholder="$t('Departure Date')" />
                                                                                    <span class="departure_day">
                                                                                        {{ this.multi_city[index +
                                                                                            1].date ? $t(new
                                                                                                Date(multi_city[index +
                                                                                                    1].date).toLocaleDateString('en-US',
                                                                                                        { weekday: 'long' })) : '' }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button type="button"
                                                                class="btn btn_theme btn_md btn-search-flight-next"
                                                                @click="search">{{
                                                                    $t('Search Flight')
                                                                }}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.allowed_to_choose_need_help_option == 1 && this.can_request_help == 'true'"
                                class="col-md-12 text-center mt-4">
                                <router-link to="/booking-help" class="text-danger">
                                    {{ $t('Do you need help booking a flight?') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import jsonData from '@/assets/file/airports-v2.json';
import { toast } from 'vue3-toastify';
import { debounce } from 'lodash';

export default {
    name: "Form",
    data() {
        return {
            number: 1,
            pnumber: 1,
            cnumber: 0,
            inumber: 0,
            cabin: 'ECONOMY',
            arrival: '',
            arrival_name: '',
            date: null,
            arrival_date: null,
            allowed_companion_numbers: 0,
            max_free_period_between_arrival_departure: 0,
            departure_time_condition: 0,
            arrival_time_condition: 0,
            arrival_start_date: '',
            arrival_end_date: '',
            departure_start_date: '',
            departure_end_date: '',
            allowedDegree: [],
            departure_min_date: new Date(),
            departure_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            arrival_min_date: new Date(),
            arrival_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            allowed_to_choose_need_help_option: 0,
            is_searching: false,
            noResults: false,
            airports_data: [],
            departure: null,
            departure_name: '',
            is_searching_multi: [{ departure: false, arrival: false, }, { departure: false, arrival: false, },],
            multi_city: [
                {
                    from: '',
                    to: '',
                    date: null,
                },
                {
                    from: '',
                    to: '',
                    date: null,
                }
            ],
            rowData: [{ name: '' }],
            isHidden: false,
            can_request_help: true,
            booking_for_companion: false,
            companions: [],
            tripType: '',
            multi_destinations: 1,
            allowed_request_return_flight_only: 1,
            airport_name_multi: [{ departure: '', arrival: '', }, { departure: '', arrival: '', },],
        };
    },
    methods: {
        handle_booking_for_companion() {
            if (this.booking_for_companion) {
                this.companions.forEach(element => {
                    if (element.data_status != 'Completed' && !element.booking) {
                        this.number = 1;
                        this.pnumber = 1;
                        this.booking_for_companion = false;
                        toast.error(this.$t('You have to complete your companion information'), {
                            autoClose: 3500,
                            position: toast.POSITION.TOP_CENTER,
                            closeButton: false
                        });
                        return;
                    }
                    else {
                        this.number += 1;
                        this.pnumber += 1;
                    }
                });
            } else {
                this.number = 1;
                this.pnumber = 1;
            }
        },
        handleInternalMultiCity(date) {
            setTimeout(() => {
                $(document).find('.dp__action_select').click();
            }, 10);
        },
        handleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        arrivalHandleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.arrival_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        arrivalHandleDate(date) {
            var date = new Date(date);
            var day = date.toLocaleDateString('en-US', { weekday: 'long' });
            $('.arrival_day').html(this.$t(day));
        },
        increment() {
            if ((parseInt(this.number)) - 1 < this.allowed_companion_numbers) {
                this.number++;
                this.pnumber++;
            } else {
                toast.error(this.$t('The maximum number of passengers has been reached'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            }
        },
        decrement() {
            if (this.number === 0) return
            if (this.pnumber === 0 || this.pnumber === 1) return
            this.number -= 1
            this.pnumber -= 1
        },
        incrementc() {
            if ((parseInt(this.number)) - 1 < this.allowed_companion_numbers) {
                this.number++;
                this.cnumber++;
            } else {
                toast.error(this.$t('The maximum number of passengers has been reached'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            }
        },
        decrementc() {
            if (this.number === 0) return
            if (this.cnumber === 0) return
            this.number -= 1
            this.cnumber -= 1
        },
        incrementi() {
            if ((parseInt(this.number)) - 1 < this.allowed_companion_numbers) {
                this.number++;
                this.inumber++;
            } else {
                toast.error(this.$t('The maximum number of passengers has been reached'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            }
        },
        decrementi() {
            if (this.number === 0) return
            if (this.inumber === 0) return
            this.number -= 1
            this.inumber -= 1
        },
        dateDiffInDays(date1, date2) {
            const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
            const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const timeDiff = utcDate2 - utcDate1;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff;
        },
        isDateBetween(dateToCheck, startDate, endDate) {
            return dateToCheck >= startDate && dateToCheck <= endDate;
        },
        format_date(inputDate) {
            var parts = inputDate.split('/');
            var day = parts[0];
            var month = parts[1];
            var year = parts[2];
            return year + '-' + month + '-' + day;
        },
        handle_airports_search: debounce(function (val) {
            this.searchAirPorts(this.departure);
        }, 500),
        searchAirPorts(val) {
            this.noResults = false;
            if (val == '') {
                this.is_searching = false;
                return;
            } else {
                this.is_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectAirport(data) {
            this.is_searching = false;
            this.departure = data['code'];
            this.departure_name = data[this.$i18n.locale + '_name'];
        },

        handle_airports_search_oneway: debounce(function (val) {
            this.searchAirPortsOneway(this.arrival);
        }, 500),
        searchAirPortsOneway(val) {
            this.noResults = false;
            if (val == '') {
                this.is_searching = false;
                return;
            } else {
                this.is_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectAirportOneway(data) {
            this.is_searching = false;
            this.arrival = data['code'];
            this.arrival_name = data[this.$i18n.locale + '_name'];
        },
        search() {
            var type = $('#myTabContent1 .tab-pane.active').attr('id');
            if (type == 'oneway') {
                var from = 'RUH',
                    to = this.arrival,
                    date = new Date(this.date).toLocaleDateString('sv-SE'),
                    adults = this.pnumber,
                    children = this.cnumber,
                    infants = this.inumber,
                    cabin = this.cabin;
                const isDateBetween = this.isDateBetween(new Date(this.date).toLocaleDateString('sv-SE'), new Date(this.format_date(this.departure_start_date)).toLocaleDateString('sv-SE'), new Date(this.format_date(this.departure_end_date)).toLocaleDateString('sv-SE'));
                if (from == '' || to == '' || from == to) {
                    toast.error(this.$t('Invalid Location'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (this.date == null) {
                    toast.error(this.$t('Invalid Date'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (this.departure_time_condition && !isDateBetween) {
                    toast.error(this.$t('The date of departure from Riyadh must be between the dates') + this.departure_start_date + this.$t('And date') + this.departure_end_date, {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else {
                    var data = {
                        "from": from,
                        "to": to,
                        "date": date,
                        "ret_date": '',
                        "currency": 'SAR',
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin
                    };
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("tripType", 'oneway');
                    window.location.href = `/flight-search-result`;
                }
            } else if (type == 'roundtrip') {
                var from = $('.departure').val(),
                    to = $('.arrival').val(),
                    date = new Date(this.date).toLocaleDateString('sv-SE'),
                    ret_date = new Date(this.arrival_date).toLocaleDateString('sv-SE'),
                    adults = this.pnumber,
                    children = this.cnumber,
                    infants = this.inumber,
                    cabin = this.cabin;

                const differenceInDays = this.dateDiffInDays(new Date(this.date), new Date(this.arrival_date));
                const isArrivalBetween = this.isDateBetween(new Date(this.date).toLocaleDateString('sv-SE'), new Date(this.format_date(this.arrival_start_date)).toLocaleDateString('sv-SE'), new Date(this.format_date(this.arrival_end_date)).toLocaleDateString('sv-SE'));
                const isDateBetween = this.isDateBetween(new Date(this.arrival_date).toLocaleDateString('sv-SE'), new Date(this.format_date(this.departure_start_date)).toLocaleDateString('sv-SE'), new Date(this.format_date(this.departure_end_date)).toLocaleDateString('sv-SE'));

                if (from == '' || to == '' || from == to) {
                    toast.error(this.$t('Invalid Location'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (this.date == null || this.arrival_date == null || this.date > this.arrival_date) {
                    toast.error(this.$t('Invalid Date'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (differenceInDays > this.max_free_period_between_arrival_departure) {
                    toast.error(this.$t('The maximum period between the date of arrival and departure must not exceed') + this.max_free_period_between_arrival_departure + ' ' + this.$t('day'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (this.arrival_time_condition && !isArrivalBetween) {
                    toast.error(this.$t('The date of going to Riyadh must be between two dates') + this.arrival_start_date + this.$t('And date') + this.arrival_end_date, {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (this.departure_time_condition && !isDateBetween) {
                    toast.error(this.$t('The date of departure from Riyadh must be between the dates') + this.departure_start_date + this.$t('And date') + this.departure_end_date, {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else {
                    var data = {
                        "from": from,
                        "to": to,
                        "date": date,
                        "ret_date": ret_date,
                        "currency": 'SAR',
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin
                    };
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("tripType", 'roundtrip');
                    window.location.href = `/flight-search-result`;
                }
            } else {
                var locationValid = true, dateValid = true;
                const differenceInDays = this.dateDiffInDays(new Date(this.multi_city[0].date), new Date(this.multi_city[1].date));
                $.each(this.multi_city, function (index, city) {
                    if (city.from.trim() === '' || city.to.trim() === '') {
                        locationValid = false;
                        return false;
                    }
                });
                $.each(this.multi_city, function (index, city) {
                    if (!city.date) {
                        dateValid = false;
                        return false;
                    }
                });
                if (differenceInDays > this.max_free_period_between_arrival_departure) {
                    toast.error(this.$t('The maximum period between the date of arrival and departure must not exceed') + this.max_free_period_between_arrival_departure + ' ' + this.$t('day'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (!locationValid) {
                    toast.error(this.$t('Invalid Location'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else if (!dateValid) {
                    toast.error(this.$t('Invalid Date'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else {
                    var adults = this.pnumber,
                        children = this.cnumber,
                        infants = this.inumber,
                        cabin = this.cabin;
                    var info = ``;
                    $.each(this.multi_city, function (index, item) {
                        info += `&from[]=${item.from}`;
                    });
                    $.each(this.multi_city, function (index, item) {
                        info += `&to[]=${item.to}`;
                    });
                    $.each(this.multi_city, function (index, item) {
                        info += `&date[]=${new Date(item.date).toLocaleDateString('sv-SE')}`;
                    });
                    var data = {
                        "currency": 'SAR',
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin,
                        "info": info
                    };
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("tripType", 'multi_city');
                    window.location.href = `/flight-search-result`;
                }
            }
        },
        handle_airports_search_multi_city: debounce(function (val, type, index) {
            this.searchAirPortsMultiCity(val, type, index);
        }, 500),
        searchAirPortsMultiCity(val, type, index) {
            this.noResults = false;
            if (val == '') {
                this.is_searching_multi[index][type] = false;
                return;
            } else {
                this.is_searching_multi[index][type] = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectAirportMultiCity(data, index, type) {
            this.is_searching_multi[index][type] = false;
            this.airport_name_multi[index][type] = data[this.$i18n.locale + '_name'];
            if (type == 'departure') {
                this.multi_city[index]['from'] = data['code'];
            } else {
                this.multi_city[index]['to'] = data['code'];
            }
        }
    },
    mounted() {
        that = this;
        this.tripType = localStorage.getItem("tripType");
        this.can_request_help = localStorage.getItem("can_request_help");
        var searchData = JSON.parse(localStorage.getItem("searchData"));
        this.multi_destinations = localStorage.getItem("multi_destinations");
        this.allowed_request_return_flight_only = localStorage.getItem("allowed_request_return_flight_only");
        if (this.tripType == 'oneway') {
            this.pnumber = parseInt(searchData.adults);
            this.cnumber = parseInt(searchData.children);
            this.inumber = parseInt(searchData.infants);
            this.number = this.pnumber + this.cnumber + this.inumber;
            this.cabin = searchData.cabin;
            this.date = new Date(searchData.date);
            var day = this.date.toLocaleDateString('en-US', { weekday: 'long' });
            $('#oneway_flight,#oneway').find('.departure_day').html(this.$t(day));

            var jsonData = jsonDataAr;
            if (this.$i18n.locale === 'en') {
                jsonData = jsonDataEn;
            }
            var departure = jsonData.filter(item => {
                return item.code.includes(searchData.from);
            });
            var arrival = jsonData.filter(item => {
                return item.code.includes(searchData.to);
            });
            this.arrival = searchData.to;

            this.multi_city[0]['to'] = 'RUH';
            this.airport_name_multi[0]['arrival'] = arrival[0].name;

            this.multi_city[1]['from'] = 'RUH';
            this.airport_name_multi[1]['departure'] = arrival[0].name;

            $('#oneway_flight,#roundtrip').find('.arrival').val(searchData.from);
            $('#oneway_flight,#roundtrip').find('.arrival_name').html(departure[0].name);

            $('#oneway_flight,#oneway').find('.arrival').val(searchData.to);
            $('#oneway_flight,#oneway').find('.departure_name').html(departure[0].name);
            $('#oneway_flight,#oneway').find('.arrival_name').html(arrival[0].name);

            $('#oneway_flight,#oneway').find('#adult').val(searchData.adults);
            $('#oneway_flight,#oneway').find('#kid').val(searchData.children);
            $('#oneway_flight,#oneway').find('#infant').val(searchData.infants);

            this.allowed_to_choose_need_help_option = localStorage.getItem('allowed_to_choose_need_help_option');

            const allowedDegreeString = localStorage.getItem('allowed_degree');
            this.allowedDegree = JSON.parse(allowedDegreeString);

            this.allowed_companion_numbers = localStorage.getItem('allowed_companion_numbers');
            this.max_free_period_between_arrival_departure = localStorage.getItem('max_free_period_between_arrival_departure');

            this.departure_time_condition = localStorage.getItem('departure_time_condition');
            this.departure_start_date = localStorage.getItem('departure_start_date');
            this.departure_end_date = localStorage.getItem('departure_end_date');

            if (this.departure_time_condition == 1) {
                this.arrival_min_date = this.format_date(this.departure_start_date);
                this.arrival_max_date = this.format_date(this.departure_end_date);
                //this.arrival_date = new Date(this.format_date(this.departure_start_date));
            }

            this.arrival_time_condition = localStorage.getItem('arrival_time_condition');
            this.arrival_start_date = localStorage.getItem('arrival_start_date');
            this.arrival_end_date = localStorage.getItem('arrival_end_date');

            if (this.arrival_time_condition == 1) {
                this.departure_min_date = this.format_date(this.arrival_start_date);
                this.departure_max_date = this.format_date(this.arrival_end_date);
            }
        } else if (this.tripType == 'roundtrip') {
            this.pnumber = parseInt(searchData.adults);
            this.cnumber = parseInt(searchData.children);
            this.inumber = parseInt(searchData.infants);
            this.number = this.pnumber + this.cnumber + this.inumber;
            this.cabin = searchData.cabin;
            this.date = new Date(searchData.date);
            var day = this.date.toLocaleDateString('en-US', { weekday: 'long' });
            $('#oneway_flight,#roundtrip').find('.departure_day').html(this.$t(day));

            this.arrival_date = new Date(searchData.ret_date);
            var day = this.arrival_date.toLocaleDateString('en-US', { weekday: 'long' });
            $('#oneway_flight,#roundtrip').find('.arrival_day').html(this.$t(day));

            var jsonData = jsonDataAr;
            if (this.$i18n.locale === 'en') {
                jsonData = jsonDataEn;
            }
            var departure = jsonData.filter(item => {
                return item.code.includes(searchData.from);
            });
            var arrival = jsonData.filter(item => {
                return item.code.includes(searchData.to);
            });
            this.departure = searchData.from;

            this.multi_city[0]['to'] = 'RUH';
            this.airport_name_multi[0]['arrival'] = arrival[0].name;

            this.multi_city[1]['from'] = 'RUH';
            this.airport_name_multi[1]['departure'] = arrival[0].name;

            $('#oneway_flight,#roundtrip').find('.arrival').val(searchData.to);
            $('#oneway_flight,#roundtrip').find('.departure_name').html(departure[0].name);
            $('#oneway_flight,#roundtrip').find('.arrival_name').html(arrival[0].name);

            $('#oneway_flight,#roundtrip').find('#adult').val(searchData.adults);
            $('#oneway_flight,#roundtrip').find('#kid').val(searchData.children);
            $('#oneway_flight,#roundtrip').find('#infant').val(searchData.infants);

            $('#oneway_flight,#oneway').find('.departure_name').html(arrival[0].name);

            this.allowed_to_choose_need_help_option = localStorage.getItem('allowed_to_choose_need_help_option');

            const allowedDegreeString = localStorage.getItem('allowed_degree');
            this.allowedDegree = JSON.parse(allowedDegreeString);

            this.allowed_companion_numbers = localStorage.getItem('allowed_companion_numbers');
            this.max_free_period_between_arrival_departure = localStorage.getItem('max_free_period_between_arrival_departure');

            this.departure_time_condition = localStorage.getItem('departure_time_condition');
            this.departure_start_date = localStorage.getItem('departure_start_date');
            this.departure_end_date = localStorage.getItem('departure_end_date');

            if (this.departure_time_condition == 1) {
                this.arrival_min_date = this.format_date(this.departure_start_date);
                this.arrival_max_date = this.format_date(this.departure_end_date);
                //this.arrival_date = new Date(this.format_date(this.departure_start_date));
            }

            this.arrival_time_condition = localStorage.getItem('arrival_time_condition');
            this.arrival_start_date = localStorage.getItem('arrival_start_date');
            this.arrival_end_date = localStorage.getItem('arrival_end_date');

            if (this.arrival_time_condition == 1) {
                this.departure_min_date = this.format_date(this.arrival_start_date);
                this.departure_max_date = this.format_date(this.arrival_end_date);
            }
        } else {
            this.pnumber = parseInt(searchData.adults);
            this.cnumber = parseInt(searchData.children);
            this.inumber = parseInt(searchData.infants);
            this.number = this.pnumber + this.cnumber + this.inumber;
            this.cabin = searchData.cabin;

            const allowedDegreeString = localStorage.getItem('allowed_degree');
            this.allowed_to_choose_need_help_option = localStorage.getItem('allowed_to_choose_need_help_option');
            this.allowedDegree = JSON.parse(allowedDegreeString);
            this.allowed_companion_numbers = localStorage.getItem('allowed_companion_numbers');
            this.max_free_period_between_arrival_departure = localStorage.getItem('max_free_period_between_arrival_departure');

            this.departure_time_condition = localStorage.getItem('departure_time_condition');
            this.departure_start_date = localStorage.getItem('departure_start_date');
            this.departure_end_date = localStorage.getItem('departure_end_date');

            if (this.departure_time_condition == 1) {
                this.arrival_min_date = this.format_date(this.departure_start_date);
                this.arrival_max_date = this.format_date(this.departure_end_date);
                //this.arrival_date = new Date(this.format_date(this.departure_start_date));
            }

            this.arrival_time_condition = localStorage.getItem('arrival_time_condition');
            this.arrival_start_date = localStorage.getItem('arrival_start_date');
            this.arrival_end_date = localStorage.getItem('arrival_end_date');

            if (this.arrival_time_condition == 1) {
                this.departure_min_date = this.format_date(this.arrival_start_date);
                this.departure_max_date = this.format_date(this.arrival_end_date);
            }

            var jsonData = jsonDataAr;
            if (this.$i18n.locale === 'en') {
                jsonData = jsonDataEn;
            }
            var arrival = jsonData.filter(item => {
                return item.code.includes('RUH');
            });
            $('#oneway_flight,#roundtrip').find('.arrival').val('RUH');
            $('#oneway_flight,#roundtrip').find('.arrival_name').html(arrival[0].name);

            $('#oneway_flight,#oneway').find('.departure_name').html(arrival[0].name);

            const params = new URLSearchParams(searchData.info);
            var from = params.getAll('from[]'),
                to = params.getAll('to[]'),
                date = params.getAll('date[]');

            $.each(from, function (index, item) {

                that.multi_city[index] = {
                    from: item,
                    to: to[index],
                    date: date[index],
                };
                that.is_searching_multi[index] = { departure: false, arrival: false, };

                var jsonData = jsonDataAr;
                if (that.$i18n.locale === 'en') {
                    jsonData = jsonDataEn;
                }
                var departure = jsonData.filter(val => {
                    return val.code.includes(item);
                });
                var arrival = jsonData.filter(val => {
                    return val.code.includes(to[index]);
                });
                that.airport_name_multi[index] = { departure: departure[0].name, arrival: arrival[0].name, };
            });
        }
    }
};
</script>
<style lang="scss">
.ui-id-1,
.ui-id-2 {
    max-height: calc(100vh - 335px);
    overflow-y: auto;
    border: 1px solid #f5eefd;
    background: #fff;
    color: #333;
    cursor: default;
    list-style: none;
    padding: 0;
    display: block;
    outline: 0;
    margin: 0px 12px;
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #e3e3e3;
        border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--main-color);
        border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--main-color);
    }
}

.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
}

.ui-menu .ui-menu-item.fl-route-list.ui-autocomplete-category {
    pointer-events: none;
    opacity: 1 !important;
}

.fl-route-list {
    border: 1px solid transparent;
    border-bottom-color: #f0eeee;
    cursor: pointer;
}

.fl-route-list.ui-autocomplete-category .fl-routes,
.fl-route-list.ui-autocomplete-category .fl-routes:hover {
    pointer-events: none;
    color: var(--main-color);
    ;
    cursor: pointer;
}

.fl-routes {
    display: block;
    outline: 0 !important;
    text-decoration: none !important;
    padding: 10px !important;
    border-color: transparent !important;
    border-bottom-color: #f0eeee;
    position: relative;
    white-space: normal;
}

.inline-block {
    display: inline-block;
}

.fl-loc {
    font-weight: 600;
}

.fl-routes .fl-country {
    display: block;
    font-size: 12px;
    margin-top: 3px;
    color: #b3b3b3;
}

.rtl .pull-right {
    float: left !important;
}

.fl-routes .fl-route-code {
    font-size: 18px;
    margin: 0;
    margin-top: 5px;
    font-weight: 700;
}

.range_plan {
    cursor: pointer;
}

.dp__input_readonly {
    padding-right: 40px !important;
}

input.chk-cabin {
    display: none;
}

.label-select-btn:has(input:checked) {
    background: var(--main-color);
    color: var(--white-color);
}

.label-select-btn label {
    width: 100%;
    text-align: initial;
}

.passengers-type {
    i {
        vertical-align: middle;
    }
}

#roundtrip {
    .dp__input {
        font-size: 15px;
    }
}
</style>
<style scoped>
@media (min-width: 1200px)
{
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1290px;
}
}
.theme_search_form_area {
    z-index: 10;
}
</style>