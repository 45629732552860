<template>
  <Preloader />
  <Header />
  <router-view />
  <ChatButton />
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Preloader from '@/components/layout/Preloader'
import ChatButton from '@/components/ChatButton.vue'
export default {
  name: 'App',
  components: {
    Header,Preloader,ChatButton
  }
}
</script>