<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('Passengers') }}</h2>
                        <ul>
                            <li>
                                <a href="/flight-search-result">
                                    {{ $t('Flight search result') }}
                                </a>
                            </li>
                            <li><span><i class="fas fa-circle"></i></span> {{ $t('Passengers') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "BannerTwo"
};
</script>