<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="p20 itinerary-content mb20">
                <div v-if="this.$route.params.id != 'flight'" class="row mb20">
                    <div class="col-md-6 text-left">
                        <h3 class="prime-color">{{ this.$route.query.from }} - {{ this.$route.query.to }}
                        </h3>
                        <span></span>
                    </div>
                    <div class="col-md-6 text-right"></div>
                </div>
                <div class="row mb20">
                    <div v-for="item in fareRules" :key="item.descriptionType" class="col-md-12 mb10">
                        <span class="second-color bold">{{ item.descriptionType }}</span>
                        <p class="pre-inline">{{ item.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
export default {
    name: 'FlightDetailsArea',
    data: () => ({
        fareRules: [],
    }),
    mounted() {
        $('.pre-loader').show();
        that = this;
        var departure_flight = JSON.parse(this.$route.params.id == 'departure' || this.$route.params.id == 'flight' ? localStorage.getItem('departure_flight') : localStorage.getItem('arrival_flight'));
        var flight = {
            data: {
                type: "flight-offers-pricing",
                flightOffers: [
                    departure_flight
                ]
            }
        };
        var url = `${this.$appUrl}/api/saudicup/pricing`;
        this.axios({
            method: 'post',
            url: url,
            data: {
                flight: flight,
            }
        }).then((response) => {
            var res = response.data.data;
            this.fareRules = res.included['detailed-fare-rules']['1'].fareNotes.descriptions;
            $('.pre-loader').hide();
        });
    }
}
</script>
<style scoped>
.flight-policies {
    padding-top: 145px;
}

.itinerary-content {
    background-color: #fff;
    border-radius: 10px;
}

.section_padding {
    padding: 50px 0;
}

.p20 {
    padding: 20px;
}

.mb20 {
    margin-bottom: 20px !important;
}

.prime-color {
    color: #1a1f71;
}

.second-color {
    color: #38bca8;
}

.mb5 {
    margin-bottom: 5px !important;
}

.pre-inline {
    white-space: pre-line;
}

.search-bar-wrapper {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    max-height: 147px;
}



.fl-back {
    background-image: url('~@/assets/img/icons/back-left.png');
    height: 24px;
    width: 27px;
}

.rtl .fl-back {
    transform: scaleX(-1);
}

.lh-50 {
    line-height: 50px;
}

.pl0 {
    padding-left: 0 !important;
}

.pr0 {
    padding-right: 0 !important;
}

.btn-search-outline {
    background: 0 0;
    border: 1px solid #e6f0ff;
    color: #e6f0ff;
    outline: 0;
    box-shadow: none;
}

.p10 {
    padding: 10px;
}

.bold {
    font-weight: 600;
    font-style: normal;
}

.padding-10-15 {
    padding: 10px 15px;
}

.bk-nav-ul,
.dh-nav-ul {
    list-style: none;
    height: 100%;
    padding: 0;
    min-height: 55px;
    padding-top: 10px;
}

.check-icon.check-white {
    background-position: -16px;
}

.check-icon {
    background-image: url('~@/assets/img/svg/check-16x12.svg');
    height: 12px;
    width: 16px;
    display: inline-block;
    margin: 0 5px;
}

.pull-left {
    float: right !important;
}

.bk-nav-ul>li,
.dh-nav-ul>li {
    display: inline-block;
    height: 100%;
    white-space: nowrap;
}

.bk-nav-ul>li>a,
.bk-nav-ul>li>div,
.dh-nav-ul>li>a,
.dh-nav-ul>li>div {
    color: rgba(255, 255, 255, .5);
    padding: 10px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.bk-nav-ul>li>a.active {
    color: #fff;
}

.height-100p {
    height: 100%;
}

.booking-nav {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    height: 65px;
}

.fl-back {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    cursor: pointer;
}
</style>