import {
    createRouter,
    createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FlightSearchResultView from '../views/FlightSearchResultView.vue'
import FlightBookingSubmission from '../views/FlightBookingSubmission.vue'
import BookingConfirmationView from '../views/BookingConfirmationView.vue'
import FlightPoliciesView from '../views/FlightPoliciesView.vue'
import ErrorView from '../views/ErrorView.vue'
import SuccessView from '../views/SuccessView.vue'
import BookedView from '../views/BookedView.vue'
import BookingHelpView from '../views/BookingHelpView.vue'
import BookingDetailsView from '../views/BookingDetailsView.vue'
import BookingHelpDetailsView from '../views/BookingHelpDetailsView.vue'
import NotFound from '../components/NotFound.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/flight-search-result',
        name: 'flight-search-result',
        component: FlightSearchResultView
    },
    {
        path: '/flight-booking-submission',
        name: 'flight-booking-submission',
        component: FlightBookingSubmission
    },
    {
        path: '/booking-confirmation/:checkoutId?',
        name: 'booking-confirmation',
        component: BookingConfirmationView
    },
    {
        path: '/flight-policies/:id',
        name: 'flight-booking-policies',
        component: FlightPoliciesView
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView
    },
    {
        path: '/successful-booking',
        name: 'successful-booking',
        component: SuccessView
    },
    {
        path: '/already-booked',
        name: 'already-booked',
        component: BookedView
    },
    {
        path: '/booking-help',
        name: 'booking-help',
        component: BookingHelpView
    },
    {
        path: '/booking-help-details',
        name: 'booking-help-details',
        component: BookingHelpDetailsView
    },
    {
        path: '/booking-details/:id',
        name: 'booking-details',
        component: BookingDetailsView
    },
    {
        path: '/:cathAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]


const scrollBehavior = (to, from, savedPosition) => {
    return savedPosition ||
        to.meta.scrollPos || {
            top: 0,
            left: 0
        }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior,
})

router.beforeEach((to, from, next) => {
    from.meta.scrollPos && (from.meta.scrollPos.top = window.scrollY)
    return next()
})

export default router