<template>

    <!-- Common Banner Area -->
    <BookingHelpDetailsBanner />

    <!-- Dashboard Area -->
    <BookingHelpDetails />

</template>
<script>

import BookingHelpDetailsBanner from '@/components/booking-help-details/BookingHelpDetailsBanner.vue'
import BookingHelpDetails from '@/components/booking-help-details/BookingHelpDetails.vue'


export default {
    name: "BookingHelpDetailsView",
    components: {
        BookingHelpDetailsBanner, BookingHelpDetails
    }
};
</script>
