<template>
    <section id="booking_help" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section_heading_center">
                        <h2>{{ $t('Departure Flight') }}</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('From') }} *</label>
                        <model-list-select :list="options" v-model="request.departure_from_airport" isDisabled="true"
                            :placeholder="$t('select2')" option-value="code" :custom-text="codeAndNameAndDesc">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('To') }} *</label>
                        <model-list-select :list="options" v-model="request.departure_to_airport"
                            :placeholder="$t('select2')" option-value="code" :custom-text="codeAndNameAndDesc"
                            isDisabled="true">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight date') }} *</label>
                        <VueDatePicker v-model="request.departure_date" :enable-time-picker="false"
                            :min-date="departure_min_date" :max-date="departure_max_date" :disabled="true"
                            @internal-model-change="handleInternal" :placeholder="$t('select2')" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight degree') }} *</label>
                        <select v-model="request.departure_booking_degree" disabled="true" class="form-control">
                            <option value="">{{ $t('select2') }}</option>
                            <option v-for="item in allowedDegree" :value="item">{{ $t(item) }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 mt-4 mb-3">
                    <h4 class="primary-color bold">{{ $t('Favorite time to flight') }}</h4>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('First option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[0]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Second option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[1]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Third option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[2]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Forth option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[3]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row mt20">
                <div class="col-md-12 mt-3">
                    <div class="section_heading_center pull-left">
                        <h2>{{ $t('Return flight') }}</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('From') }} *</label>
                        <model-list-select :list="options" v-model="request.return_from_airport"
                            :placeholder="$t('select2')" option-value="code" :custom-text="codeAndNameAndDesc"
                            isDisabled="true">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('To') }} *</label>
                        <model-list-select :list="options" v-model="request.return_to_airport" :placeholder="$t('select2')"
                            option-value="code" isDisabled="true" :custom-text="codeAndNameAndDesc">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight date') }} *</label>
                        <VueDatePicker v-model="request.return_date" :enable-time-picker="false"
                            :min-date="arrival_min_date" :max-date="arrival_max_date" :disabled="true"
                            @internal-model-change="arrivalHandleInternal" :placeholder="$t('select2')" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight degree') }} *</label>
                        <select v-model="request.return_booking_degree" class="form-control" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option v-for=" item  in  allowedDegree " :value="item">{{ $t(item) }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 mt-4 mb-3">
                    <h4 class="primary-color bold">{{ $t('Favorite time to flight') }}</h4>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('First option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[0]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Second option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[1]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Third option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[2]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Forth option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[3]" disabled="true">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import { ModelListSelect } from 'vue-search-select';
import "vue-search-select/dist/VueSearchSelect.css";
import { toast } from 'vue3-toastify';
export default {
    name: 'BookingHelpArea',
    components: { ModelListSelect },
    data: () => ({
        options: [],
        item: {},
        request: {
            departure_from_airport: '',
            departure_to_airport: 'RUH',
            departure_date: null,
            departure_booking_degree: '',
            return_from_airport: 'RUH',
            return_to_airport: '',
            return_date: null,
            return_booking_degree: '',
            prefered_departure_times: [],
            prefered_return_times: [],
            action_type: '',
            action_type_id: '',
            guest_id: []
        },
        max_free_period_between_arrival_departure: 0,
        departure_time_condition: 0,
        arrival_time_condition: 0,
        arrival_start_date: '',
        arrival_end_date: '',
        departure_start_date: '',
        departure_end_date: '',
        allowedDegree: [],
        departure_min_date: new Date(),
        departure_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        arrival_min_date: new Date(),
        arrival_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        times: [],
        is_sending: false
    }),
    methods: {
        codeAndNameAndDesc(item) {
            return `${item.name} ( ${item.code} ) - ${item.country} / ${item.city}`
        },
        format_date(inputDate) {
            var parts = inputDate.split('/');
            var day = parts[0];
            var month = parts[1];
            var year = parts[2];
            return year + '-' + month + '-' + day;
        },
        handleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.request.departure_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        arrivalHandleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.request.return_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        validateRequest(request) {
            for (const key in this.request) {
                if (this.request[key] === '' || this.request[key] === null || (Array.isArray(this.request[key]) && this.request[key].length === 0)) {
                    return false;
                }
            }
            if (this.request.prefered_departure_times.length !== 4 || this.request.prefered_return_times.length !== 4) {
                return false;
            }
            return true;
        },
        dateDiffInDays(date1, date2) {
            const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
            const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const timeDiff = utcDate2 - utcDate1;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff;
        },
    },
    mounted() {
        const guest_data = JSON.parse(localStorage.getItem('guest_data'));
        var need_flight_helping = guest_data.need_flight_helping;
        this.request = {
            departure_from_airport: need_flight_helping.departure_from_airport,
            departure_to_airport: need_flight_helping.departure_to_airport,
            departure_date: need_flight_helping.departure_date,
            departure_booking_degree: need_flight_helping.departure_booking_degree,
            return_from_airport: need_flight_helping.return_from_airport,
            return_to_airport: need_flight_helping.return_to_airport,
            return_date: need_flight_helping.return_date,
            return_booking_degree: need_flight_helping.return_booking_degree,
            prefered_departure_times: need_flight_helping.prefered_departure_times,
            prefered_return_times: need_flight_helping.prefered_return_times,
        };
        this.options = jsonDataAr;
        if (this.$i18n.locale === 'en') {
            this.options = jsonDataEn;
        }
        const allowedDegreeString = localStorage.getItem('allowed_degree');
        this.allowedDegree = JSON.parse(allowedDegreeString);
    }
}
</script>
<style lang="scss" scoped>
.section_padding {
    padding: 50px 0;
}
label{
    font-size: 14px;
    margin-bottom: 8px;
}
.form-group{
    margin-bottom: 1rem;
}

.rtl{
    .ui.dropdown{
    text-align: right;
}
}
</style>