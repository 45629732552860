<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb20">
                        <div id="pass-adult-info" class="mb20">
                            <div id="bk-pass-all">
                                <div class="row list">
                                    <div v-for="item in passengers" :data-id="item.id" :data-type="item.type" :key="item.id"
                                        class="col-md-4 col-12 col-sm-12 mb15 pass-card">
                                        <div class="pass-spec-container">
                                            <div class="not-pass"></div>
                                            <div class="row no-margin align">
                                                <div class="col-md-12 col-sm-12 col-xs-12 col-12 p-0 no-padding col-middle">
                                                    <label>
                                                        <div class="pass-details">
                                                            <div class="right">
                                                                <div
                                                                    style="display: flex;justify-content: space-between;gap:8px;">
                                                                    <span class="clearfix no-margin w-100">
                                                                        {{ item.first_name }} {{
                                                                            item.last_name }}
                                                                    </span>
                                                                    <div class="icheckbox_square-green"
                                                                        style="position: relative;">
                                                                        <label class="checkbox-container">
                                                                            <input type="checkbox" class="select-pass"
                                                                                :data-id="item.id" :data-type="item.type"
                                                                                v-on:change="select_pass($event, item.id, item.type)">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <span class="clearfix no-margin">
                                                                    {{ $t('Passport') }} ({{ $i18n.locale ===
                                                                        'en'
                                                                        ?
                                                                        getCountriesByCode(item.passport_issuing_country)['en_label']
                                                                        :
                                                                        getCountriesByCode(item.passport_issuing_country)['ar_label']
                                                                    }})
                                                                </span>
                                                                <div
                                                                    style="display: flex;flex-wrap: wrap;-webkit-box-pack: justify;justify-content: space-between;">
                                                                    <div class="">
                                                                        {{ item.passport_number }}
                                                                    </div>
                                                                    <div class="">
                                                                        {{ $t('Expires') }}
                                                                        <time>
                                                                            {{ $t(new
                                                                                Date(item.passport_expiry_date).toLocaleDateString('en-US',
                                                                                    {
                                                                                        month:
                                                                                            'long'
                                                                                    })) }}
                                                                            {{ new
                                                                                Date(item.passport_expiry_date).toLocaleDateString('en-US',
                                                                                    { day: 'numeric' }) }}

                                                                            {{ new
                                                                                Date(item.passport_expiry_date).getFullYear()
                                                                            }}</time>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-center mt-4">
                    <button type="button" class="btn btn_theme btn_md w-25 " @click="next_summary">{{
                        $t('Next') }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import airportsAr from '@/assets/file/ar-airports.json';
import airportsEn from '@/assets/file/en-airports.json';
import countriesData from '@/assets/file/countries.json';
import { toast } from 'vue3-toastify';
export default {
    name: 'PassengerArea',
    data: () => ({
        countries: [],
        passengers: [],
        adults: [],
        infants: [],
        children: [],
        years: [],
        searchData: []
    }),
    methods: {
        getCountriesByCode(code) {
            var info = this.countries.filter(item => {
                return item.en_label.includes(code);
            });
            return info[0];
        },
        diff_years(dt2, dt1) {
            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= (60 * 60 * 24);
            return Math.abs(Math.round(diff / 365.25));
        },
        next_summary() {
            const pass = [this.adults, this.infants, this.children];
            var passengers = pass.flat();
            var adult = this.searchData.adults,
                child = this.searchData.children,
                infant = this.searchData.infants;
            if (adult + child + infant != passengers.length) {
                toast.error(this.$t('Please select passenger(s)'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
                return;
            }
            this.passengers = this.passengers.filter((item) => {
                return passengers.includes(item.id);
            });
            passengers = [];
            this.passengers.forEach(element => {
                passengers.push(element.encrypted_id);
            });
            localStorage.setItem('passengers', JSON.stringify(passengers));
            localStorage.setItem('passengersDetails', JSON.stringify(this.passengers));
            this.$router.push(`/booking-confirmation`);
        },
        select_pass(event, id, type) {
            console.log($(event.target).is(":checked"));
            var adult = this.searchData.adults,
                child = this.searchData.children,
                infant = this.searchData.infants;
            if ($(event.target).is(":checked")) {
                if (type == 'Adult') {
                    this.adults.push(id);
                } else if (type == 'Child') {
                    this.children.push(id);
                } else {
                    this.infants.push(id);
                }
            } else {
                if (type == 'Adult') {
                    const index = this.adults.indexOf(id);
                    if (index > -1) {
                        this.adults.splice(index, 1);
                    }
                } else if (type == 'Child') {
                    const index = this.children.indexOf(id);
                    if (index > -1) {
                        this.children.splice(index, 1);
                    }
                } else {
                    const index = this.infants.indexOf(id);
                    if (index > -1) {
                        this.infants.splice(index, 1);
                    }
                }
            }
            $('.pass-card').each(function () {
                var id = $(this).data('id'),
                    type = $(this).data('type');
                if (type == 'Adult' && !that.adults.includes(id) && that.adults.length == adult) {
                    $(this).attr('disabled', true);
                } else if (that.adults.length < adult && !that.adults.includes(id)) {
                    $(this).attr('disabled', false);
                }
                if (type == 'Child' && !that.children.includes(id) && that.children.length == child) {
                    $(this).attr('disabled', true);
                } else if (that.children.length < child && !that.children.includes(id)) {
                    $(this).attr('disabled', false);
                }
                if (type == 'Infant' && !that.infants.includes(id) && that.infants.length == infant) {
                    $(this).attr('disabled', true);
                } else if (that.infants.length < infant && !that.infants.includes(id)) {
                    $(this).attr('disabled', false);
                }
            });
        },
        jquery() {
            $(document).on('click', '.w3-bar-item', function () {
                var id = $(this).data('id');
                $('.w3-bar-item').removeClass('active');
                $(this).addClass('active');
                $('.w3-container').hide();
                $(id).show();
            });
        }
    },
    mounted() {
        that = this;
        this.jquery();
        this.adults = [];
        this.children = [];
        this.infants = [];
        this.searchData = JSON.parse(localStorage.getItem("searchData"));
        $('.pre-loader').show();
        var jsonData = airportsAr;
        if (this.$i18n.locale === 'en') {
            jsonData = airportsEn;
        }
        this.countries = countriesData;

        const guest_id = localStorage.getItem("guest_id");
        const user_token = localStorage.getItem("user_token");
        const group_id = localStorage.getItem("group_id");

        if (guest_id) {
            var url = `${this.$saudicupAppUrl}/api/guest_by_encrypted_id/${guest_id}`;
            this.axios.get(url).then((response) => {
                const data = response.data.data;
                if (!data.booking) {
                    if (data.data_status != "Uncompleted") {
                        var dt1 = new Date(data.birthdate);
                        var dt2 = new Date();
                        var interval = this.diff_years(dt1, dt2);
                        var type = '';

                        if (interval <= 2) {
                            type = 'Infant';
                        } else if (interval <= 11) {
                            type = 'Child';
                        } else {
                            type = 'Adult';
                        }
                        var passengers = [
                            {
                                "id": data.id,
                                "encrypted_id": data.encrypted_id,
                                "type": type,
                                "title": data.gender == 'Female' ? 'ms' : 'mr',
                                "first_name": data.en_first_name,
                                "last_name": data.en_last_name,
                                "registration_number": data.registration_number,
                                "category": data.category.category_name,
                                "birthday": data.birthdate,
                                "email": data.email,
                                "nationality": data.nationalties,
                                "passport_number": data.passport_number,
                                "passport_issuing_country": data.place_of_passport_issue,
                                "passport_expiry_date": data.passport_expire_date,
                            }
                        ];
                        data.companions.forEach(element => {
                            if (element.data_status != "Uncompleted" && !element.booking) {
                                var dt1 = new Date(element.birthdate);
                                var dt2 = new Date();
                                var interval = this.diff_years(dt1, dt2);
                                var type = '';

                                if (interval <= 2) {
                                    type = 'Infant';
                                } else if (interval <= 11) {
                                    type = 'Child';
                                } else {
                                    type = 'Adult';
                                }
                                passengers.push({
                                    "id": element.id,
                                    "encrypted_id": element.encrypted_id,
                                    "type": type,
                                    "title": element.gender == 'Female' ? 'ms' : 'mr',
                                    "first_name": element.en_first_name,
                                    "last_name": element.en_last_name,
                                    "registration_number": element.registration_number,
                                    "category": element.category.category_name,
                                    "birthday": element.birthdate,
                                    "email": element.email,
                                    "nationality": element.nationalties,
                                    "passport_number": element.passport_number,
                                    "passport_issuing_country": element.place_of_passport_issue,
                                    "passport_expiry_date": element.passport_expire_date,
                                });
                            }
                        });
                        this.passengers = passengers;
                    }
                } else {
                    this.$router.replace(`/already-booked`);
                }
                $('.pre-loader').hide();
            });
        }
        else if (user_token) {
            var url = `${this.$saudicupAppUrl}/api/get-flight-group-by-url/${group_id}`;
            this.axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + user_token
                }
            }).then((response) => {
                const data = response.data.data;
                var passengers = [];
                data.members.forEach(element => {
                    if (element.data_status != "Uncompleted" && !element.booking) {
                        var dt1 = new Date(element.birthdate);
                        var dt2 = new Date();
                        var interval = this.diff_years(dt1, dt2);
                        var type = '';

                        if (interval <= 2) {
                            type = 'Infant';
                        } else if (interval <= 11) {
                            type = 'Child';
                        } else {
                            type = 'Adult';
                        }
                        passengers.push({
                            "id": element.id,
                            "encrypted_id": element.encrypted_id,
                            "type": type,
                            "title": element.gender == 'Female' ? 'ms' : 'mr',
                            "first_name": element.en_first_name,
                            "last_name": element.en_last_name,
                            "registration_number": element.registration_number,
                            "category": element.category.category_name,
                            "birthday": element.birthdate,
                            "email": element.email,
                            "nationality": element.nationalties,
                            "passport_number": element.passport_number,
                            "passport_issuing_country": element.place_of_passport_issue,
                            "passport_expiry_date": element.passport_expire_date,
                        });
                    }
                });
                this.passengers = passengers;
                $('.pre-loader').hide();
            });
        } else {
            $('.pre-loader').hide();
            this.$router.replace(`/error`);
        }

    },
}
</script>
<style land="scss">
.wrapper__drawer-container {
    width: 50% !important;
    transition: none !important;
}

.lv-button.--outlined {
    height: 40px;
}

.lv-drawer__wrapper .wrapper__drawer-container .drawer-container__header {
    padding: 12px 20px;
}

.lv-input__label {
    margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.section_padding {
    padding: 50px 0;
}
.mb20 {
    margin-bottom: 20px !important;
}

.min-wrapper {
    min-height: calc(100vh - 140px);
}

.red-border {
    border-color: #ea1c18 !important;
}

.text-red {
    color: #c00;
}

.pass-con {
    background-color: #0a0f57;
    color: #fff;
    padding: 15px;
    border-radius: 3px;
}

.row.align>.col-middle {}

.rtl .pass-count {
    margin-right: 20px;
    margin-left: 0 !important;
}

.rtl .pass-count span {
    font-size: 14px;
}

.pass-con .btn,
.pass-con .btn:active,
.pass-con .btn:after,
.pass-con .btn:focus,
.pass-con .btn:hover {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.mb30 {
    margin-bottom: 30px !important;
}

.row.align {
    padding: 0;
}

.sticky-parent {
    transition: 1s;
    bottom: 50px;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 100;
    top: 145px;
}

.pass-ticket-con {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    transition: .8s;
}

.oneway,
.oneway-2x,
.oneway-left,
.roundtrip,
.roundtrip-2x {
    background-repeat: no-repeat;
    display: inline-block;
    width: 50px;
    height: 26px;
    background-position: center;
    vertical-align: middle;
}

.flip {
    transform: scaleX(-1);
}

.fl-30-ico.calendar-pink-ico {
    background-position: -180px -300px;
}

.fl-30-ico {
    background-image: url('~@/assets/img/icons/30x30.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.fl-30-ico.passengers {
    background-position: 0 -90px;
}

.ticket-hole:after {
    right: -30px;
}

.ticket-hole:after,
.ticket-hole:before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #e6f0ff;
    position: absolute;
    top: -12px;
    border-radius: 50%;
}

.ticket-hole:before {
    left: -30px;
}

.ticket-hole>hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px dashed #a3a3a3;
}

.dash-line {
    border-top: 1px dashed #a3a3a3;
}

.ticket-hole {
    position: relative;
}

.mb0 {
    margin-bottom: 0px;
}

.passenger-info {
    padding-top: 145px;
}

.pass-icon {
    background-image: url('~@/assets/img/icons/addpass.png');
    width: 30px;
    height: 20px;
    margin: 0 5px;
}

.h4,
h4 {
    font-size: 18px;
}

.pass-icon {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.frm-js-lbl input.form-control {
    padding: 5px 15px 0 5px;
    height: 100%;
    appearance: none;
    background: 0 0;
    background: 0 0;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0;
    border-bottom: 1px solid #b3b3b3;
    position: relative;
    text-overflow: ellipsis;
    border-radius: 0;
}

.frm-js-lbl label {
    right: 0px;
    left: inherit;
}

.frm-js-lbl {
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
    height: 36px;
}

label {
    font-weight: 400;
    display: block;
    margin-bottom: 0;
}

.fl-pass-info .pass-image,
.pass-spec-container .pass-image {
    background-color: #2c6dfb;
    color: #fff;
    font-size: 12px;
    border: 0;
    border-radius: 50%;
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    text-align: center;
    padding: 1;
    width: 40px;
    height: 40px;
    line-height: 35px;
    padding-top: 3px;
}

.mb20 {
    margin-bottom: 20px !important;
}


.btn-fa {
    width: 25px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.edit-icon {
    background-image: url('~@/assets/img/icons/edit.png');
}

.delete-icon,
.edit-icon {
    width: 23px;
    height: 23px;
}

.edit-icon {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.rtl .pass-spec-container .pass-details .right {
    text-align: right;
}

.pass-spec-container .pass-details .right {
    direction: ltr;
}

.icheckbox_square,
.icheckbox_square-blue,
.icheckbox_square-green,
.icheckbox_square-grey,
.icheckbox_square-orange,
.icheckbox_square-pink,
.icheckbox_square-purple,
.icheckbox_square-red,
.icheckbox_square-yellow,
.iradio_square,
.iradio_square-blue,
.iradio_square-green,
.iradio_square-grey,
.iradio_square-orange,
.iradio_square-pink,
.iradio_square-purple,
.iradio_square-red,
.iradio_square-yellow {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
}

.no-margin {
    margin: 0;
}

.checkbox-container {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.checkbox-container input[type="checkbox"]:checked~.checkmark {
    background: url('~@/assets/img/green.png') no-repeat;
    background-position: -49px -1px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input[type="checkbox"]:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.fl-ico.add {
    background-position: -92px 0;
}

#passenger_info_render {
    max-height: calc(100vh - 245px);
    overflow-y: auto;
}

.mdl-close {
    left: 5px;
    right: inherit;
}

.mdl-close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 12px;
    background: #fff;
    text-align: center;
    justify-content: center;
    line-height: 27px;
    border-radius: 50%;
    color: #b3b3b3;
    z-index: 100;
    cursor: pointer;
}

#passenger_info_render .fl-lbl {
    color: #cb538d;
}

.h-input {
    height: 40px;
    line-height: 40px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;
    border-radius: 0;
}

.btn {
    border-radius: 3px;
}

#passenger_info_render select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    padding: 0;
}

#passenger_info_render::-webkit-scrollbar {
    width: 6px;
}

#passenger_info_render::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e3e3e3;
    border-radius: 25px;
}

#passenger_info_render::-webkit-scrollbar-thumb {
    background: #196dfb;
    border-radius: 25px;
}

#passenger_info_render::-webkit-scrollbar-thumb:hover {
    background: #196dfb;
}

.modal-content {
    border: none;
}

.fl-ico.circle-close {
    background-position: -115px 0;
}

.member-con {
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    position: relative;
    background: #fff;
}

.p10 {
    padding: 10px;
}

#passenger_info_render .btn-close-id {
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 100;
    width: 45px;
}

.rtl #passenger_info_render .btn-close-id {
    left: 0px !important;
    right: unset;
}

.pull-left {
    float: left !important;
}

.relative {
    position: relative;
}

.fl-30-ico.error-alert {
    background-position: -150px -266px;
}

.search-bar-wrapper {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    max-height: 147px;
}



.fl-back {
    background-image: url('~@/assets/img/icons/back-left.png');
    height: 24px;
    width: 27px;
}

.rtl .fl-back {
    transform: scaleX(-1);
}

.lh-50 {
    line-height: 50px;
}

.pl0 {
    padding-left: 0 !important;
}

.pr0 {
    padding-right: 0 !important;
}

.btn-search-outline {
    background: 0 0;
    border: 1px solid #e6f0ff;
    color: #e6f0ff;
    outline: 0;
    box-shadow: none;
}

.p10 {
    padding: 10px;
}

.bold {
    font-weight: 500;
    font-style: normal;
}

.padding-10-15 {
    padding: 10px 15px;
}

.bk-nav-ul,
.dh-nav-ul {
    list-style: none;
    height: 100%;
    padding: 0;
    min-height: 55px;
    padding-top: 10px;
}

.check-icon.check-white {
    background-position: -16px;
}

.check-icon {
    background-image: url('~@/assets/img/svg/check-16x12.svg');
    height: 12px;
    width: 16px;
    display: inline-block;
    margin: 0 5px;
}

.rtl .pull-left {
    float: right !important;
}

.bk-nav-ul>li,
.dh-nav-ul>li {
    display: inline-block;
    height: 100%;
    white-space: nowrap;
}

.bk-nav-ul>li>a,
.bk-nav-ul>li>div,
.dh-nav-ul>li>a,
.dh-nav-ul>li>div {
    color: rgba(255, 255, 255, .5);
    padding: 10px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.bk-nav-ul>li>a.active {
    color: #fff;
}

.height-100p {
    height: 100%;
}

.flight_sidebar_right {
    justify-content: center;

    .flight_right_arrow_sidebar {
        h6 {
            margin-top: 10px;
        }
    }
}

.booking-nav {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    height: 65px;
}

.fl-back {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    cursor: pointer;
}

.pass-card[disabled=disabled] .not-pass {
    display: block;
    transition: .3;
}

.pass-card .not-pass {
    position: absolute;
    background-color: rgba(147, 147, 147, .57);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 2;
    display: none;
    transition: .3;
}

@media only screen and (max-width: 600px) {
    .pass-con .row.align {
        display: flex !important;
    }

    .pass-con .btn {
        margin-top: 15px;
    }

    #new-passenger-form .col-md-12 {
        width: 100%;
    }

    #edit-passenger-form .col-md-12 {
        width: 100%;
    }

    .sticky-parent {
        position: relative !important;
        top: 0px !important;
    }

}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    cursor: pointer;
}

.p15 {
    padding: 15px !important;
}

.modal-content {
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
}

.fl-ico {
    background-image: url('~@/assets/img/icons/23x23.svg');
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    width: 23px;
    height: 23px;
    position: relative;
    margin: 0 5px;
}

.fl-ico.close {
    background-position: -138px -23px;
    opacity: 1 !important;
}

.w3-bar {
    width: 90%;
    display: block;
    border-style: solid;
    border-color: rgb(227, 232, 239);
    border-radius: 4px;
    border-width: 1px;
    margin: auto;
    margin-top: 10px;
    border-bottom: 2px solid rgb(201, 211, 223);
}

.w3-bar-item {
    width: 33.3%;
    height: 45px;
    font-size: 14px;
    border: none;
    background: none;
    position: relative;

}

.w3-container {
    margin: auto;
    width: 90%;
    padding: 20px 0px;
}

.w3-bar-item.active:before {
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2px;
    transition: all .3s;
    transform: scale(1);
    background-color: #8b3eea;
}

.pass-spec-container {
    background: #fff;
    max-height: 109px;
    cursor: pointer;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.08) 0px 2px 6px 0px, rgba(50, 50, 93, 0.05) 0px 0px 0px 1px;
}
</style>