<template>
  <!-- Banner Area -->
  <Banner />

  <!-- Form Area -->
  <Form />
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/home/Banner.vue'
import Form from '@/components/home/Form.vue'

export default {
  name: 'HomeView',
  components: {
    Banner, Form
  }
}
</script>