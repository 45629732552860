<template>

    <!-- Common Banner Area -->
    <BookingConfirmationBanner />

    <!-- Booking Confirmation Submission Areas -->
    <BookingConfirmation />

</template>
<script>
import BookingConfirmationBanner from '@/components/booking-confirmation/BookingConfirmationBanner.vue'
import BookingConfirmation from '@/components/booking-confirmation/BookingConfirmation.vue'

export default {
    name: "BookingConfirmationView",
    components: {
        BookingConfirmationBanner, BookingConfirmation
    }
};
</script>