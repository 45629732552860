<template>

    <!-- Common Banner Area -->
    <FlightPoliciesBanner />

    <!-- Dashboard Area -->
    <FlightPolicies />

</template>
<script>

import FlightPoliciesBanner from '@/components/flight-policies/FlightPoliciesBanner.vue'
import FlightPolicies from '@/components/flight-policies/FlightPolicies.vue'


export default {
    name: "FlightPoliciesView",
    components: {
        FlightPoliciesBanner, FlightPolicies
    }
};
</script>
