<template>
    <div>
        <header class="main_header_arae">
            <div class="navbar-area is-sticky">
                <div class="main-responsive-nav">
                    <div class="container">
                        <div class="main-responsive-menu mean-container">
                            <nav class="navbar">
                                <div class="container">
                                    <div class="logo">
                                        <a :href="redirect_url">
                                            <img src="../../assets/img/logo.png" alt="logo" height="60">
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="main-navbar">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" :href="redirect_url">
                            <img src="../../assets/img/logo.png" alt="logo" height="60">
                        </a>

                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                <li class="nav-item m-0">
                                    <button type="button" role="button"
                                        class="btn btn-default pl-0 pr-0" id="chat-circle">
                                        <span class="filter-option pull-left">
                                            <div class="cur-con">
                                                <img src="../../assets/icons/message.png" width="24" class="mb-1">
                                                <span class="ml10 mr10 font-14">
                                                    {{$t('Contact Us')}}
                                                </span>
                                            </div>
                                        </span>&nbsp;
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button type="button" data-toggle="dropdown" role="button"
                                        class="btn dropdown-toggle btn-default btn-lang">
                                        <span class="filter-option pull-left">
                                            <div class="cur-con">
                                                <span :class="language == 'ar' ? 'flag flag-sa' : 'flag flag-us'"></span>
                                                <span class="ml10 mr10 font-14">{{ language == 'ar' ? 'Arabic' : 'English'
                                                }}</span>
                                            </div>
                                        </span>&nbsp;
                                    </button>
                                    <ul class="dropdown-menu lang inner">
                                        <li>
                                            <a href="javascript:void(0);" data-lang="ar">
                                                <div class="cur-con">
                                                    <span class="flag flag-sa"></span>
                                                    <span class="ml10 mr10">Arabic</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" data-lang="en">
                                                <div class="cur-con">
                                                    <span class="flag flag-us"></span>
                                                    <span class="ml10 mr10">English</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
var that;
import $ from 'jquery';
export default {
    name: 'Header',
    data() {
        return {
            redirect_url: '',
            language: '',
            isSticky: false,
        }
    },
    methods: {
        Jquery() {
            $(document).on('click', '.lang a', function () {
                var lang = $(this).data('lang');
                if (that.language != lang) {
                    if (that.language == 'en') {
                        localStorage.setItem("locale", 'ar');
                    } else {
                        localStorage.setItem("locale", 'en');
                    }
                    that.language = lang;
                    window.location.reload();
                }
            });
        }
    },
    mounted() {
        that = this;
        this.Jquery();
        this.redirect_url = localStorage.getItem("redirect_url");
        window.addEventListener('scroll', () => {
            let scroll = window.scrollY
            if (scroll >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })
        document.addEventListener('click', function (e) {
            // Hamburger menu
            if (e.target.classList.contains('hamburger-toggle')) {
                e.target.children[0].classList.toggle('active');
            }
        });
        this.language = localStorage.getItem("locale") ?? 'en';
        if (this.language == 'ar') {
            $('body').addClass('rtl');
        } else {
            $('body').removeClass('rtl');
        }
        if (localStorage.getItem("locale")) {
            this.$i18n.locale = localStorage.getItem("locale");
            this.lang = localStorage.getItem("locale");
        } else {
            localStorage.setItem("locale", this.$i18n.locale);
        }
    }
}
</script>

<style lang="scss" scoped>
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

#login-modal .modal-dialog {
    width: 370px;
}

.modal.show .modal-dialog {
    transform: none;
}

#login-modal .fl-ico.close {
    background-position: -138px -23px;
    opacity: 1 !important;
}

#login-modal .fl-ico {
    background-image: url('~@/assets/img/icons/23x23.svg');
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    width: 23px;
    height: 23px;
    position: relative;
}

.fl-ico.close {
    margin: 0;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    cursor: pointer;
}

#login-modal footer,
#login-modal header {
    display: none;
}

.no-border {
    border: none !important;
}

.mt15 {
    margin-top: 15px !important;
}

#login-modal .modal-body .modal-header {
    display: block;
    border: none;
}

#login-modal .mdl-close {
    left: 5px;
    right: inherit;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 12px;
    background: #fff;
    text-align: center;
    justify-content: center;
    line-height: 27px;
    border-radius: 50%;
    color: #b3b3b3;
    z-index: 100;
    cursor: pointer;
}

#login-modal .btn-fa-primary {
    background-color: #8b3eea;
    border: 0;
    color: #fff;
    outline: 0;
    box-shadow: none;
    transition: .3s;
    border-radius: 3px;
    font-size: 18px;
}

#login-modal .btn-block {
    display: block;
    width: 100%;
}

.p15 {
    padding: 15px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

#login-modal .modal-content {
    border: none;
}

#login-modal .modal-body .h1,
#login-modal .modal-body .h2,
#login-modal .modal-body .h3,
#login-modal .modal-body h1,
#login-modal .modal-body h2,
#login-modal .modal-body h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

#login-modal .fix-ltr {
    direction: ltr !important;
}

#login-modal .fa-input-group {
    border: 1px solid #bcbcbc;
    white-space: nowrap;
}

#login-modal .bootstrap-select.btn-group:not(.input-group-btn),
#login-modal .bootstrap-select.btn-group[class*=col-] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

#login-modal .fix-ltr {
    direction: ltr !important;
}

#login-modal .fa-custom-picker.bootstrap-select .btn {
    width: inherit;
    border: 0;
    height: 36px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100px;
    padding: 0 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

    li {
        display: flex;
    }
}

#login-modal .fa-input-group .form-control {
    display: inline-block;
    width: calc(100% - 114px);
    border: 0;
    padding: 6px 15px 5px 0;
    cursor: text;
}

.open .dropdown-menu {
    display: block;
}

.dropdown-menu>li>a {
    display: block;
    padding: 0;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #000 !important;
    white-space: nowrap;
}

.mr10 {
    margin-right: 10px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.flag {
    display: inline-block;
    width: 16px;
    height: 11px;
    background: url('~@/assets/flags/flags.png') no-repeat;
}

#login-modal .form-control {
    display: block;
    width: 100%;
    height: 34px !important;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-code {
    font-size: 29px;
    width: 100%;
    text-align: center;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 2px solid #888;
    display: inline-block;
    vertical-align: middle;
    padding: 7px 0;
    margin: 0 3px;

    &:focus-visible {
        outline: none;
    }
}

.bold {
    font-weight: 600 !important;
    font-style: normal;
}

.mt10 {
    margin-top: 10px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.red-border {
    border-color: #ea1c18 !important;
}

.dropdown-toggle::after {
    content: none;
}

.topbar-others-options li a {
    text-align: initial;
    padding: 10px;

    i {
        padding-left: 5px;
        vertical-align: middle;
        color: #8b3eea;
    }
}

.language-option select {
    cursor: pointer;
}

.topbar-list li {
    direction: ltr;
}
</style>