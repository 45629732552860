<template>
    <!-- Common Banner Area -->
    <BookingSubmissionBanner />

    <!-- Booking Submission Areas -->
    <BookingSubmission />

</template>
  
<script>
// @ is an alias to /src
import BookingSubmissionBanner from '@/components/booking-submission/BookingSubmissionBanner.vue'
import BookingSubmission from '@/components/booking-submission/BookingSubmission.vue'
export default {
    name: 'FlightSearchResultView',
    components: {
        BookingSubmissionBanner, BookingSubmission
    }
}
</script>