<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('policies') }}</h2>
                        <ul>
                            <li>
                                <a href="javascript:void(0);" @click="this.$router.go(-2);">
                                    {{ $t('Passengers') }}
                                </a>
                            </li>
                            <li>
                                <span><i class="fas fa-circle"></i></span>
                                <a href="javascript:void(0);" @click="this.$router.go(-1);">
                                    {{ $t('Booking Confirmation') }}
                                </a>

                            </li>
                            <li>
                                <span><i class="fas fa-circle"></i></span>
                                {{ $t('policies') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "FlightPoliciesBanner"
};
</script>