<template>
    <!--Booked Areas -->
    <BookedArea />
</template>
<script>
import BookedArea from '@/components/pages/BookedArea.vue'

export default {
    name: "ErrorView",
    components: {
        BookedArea
    }
};
</script>