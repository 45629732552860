<template>

    <!-- Common Banner Area -->
    <BookingDetailsBanner />
    <BookingDetails />

</template>
<script>
import BookingDetailsBanner from '@/components/booking-details/BookingDetailsBanner.vue'
import BookingDetails from '@/components/booking-details/BookingDetails.vue'

export default {
    name: "BookingDetailsView",
    components: {
        BookingDetailsBanner, BookingDetails
    }
};
</script>