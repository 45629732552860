<template>

    <!-- Common Banner Area -->
    <SuccessBanner />

    <!--Success Areas -->
    <SuccessArea />

</template>
<script>
import SuccessBanner from '@/components/pages/SuccessBanner.vue'
import SuccessArea from '@/components/pages/SuccessArea.vue'

export default {
    name: "ErrorView",
    components: {
        SuccessBanner, SuccessArea
    }
};
</script>