<template>
    <!-- Common Banner Area -->
    <Banner />

    <!-- Form Area -->
    <Form />

    <!-- Flight Search Areas -->
    <SearchResult />

</template>
  
<script>
// @ is an alias to /src
import Banner from '@/components/search-result/Banner.vue'
import Form from '@/components/search-result/Form.vue'
import SearchResult from '@/components/search-result/SearchResult.vue'
export default {
    name: 'FlightSearchResultView',
    components: {
        Banner, Form, SearchResult
    }
}
</script>